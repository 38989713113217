import ComponentsListTableRow from './components/ComponentsListTableRow';

export const quickLinksTableColumns = [
  {
    label: 'Title',
    name: 'title',
    align: 'left',
    customRender: (row) => <ComponentsListTableRow tooltip={row?.title || ''}>{row.title}</ComponentsListTableRow>,
  },
  {
    label: 'Link',
    name: 'link',
    align: 'left',
    customRender: (row) => (
      <ComponentsListTableRow tooltip={row?.link || ''} maxWidth={400} url={row.link}>
        {row.link}
      </ComponentsListTableRow>
    ),
  },
];

export const resourcesTableColumns = [
  {
    label: 'Name',
    name: 'name',
    align: 'left',
    customRender: (row) => <ComponentsListTableRow tooltip={row?.name || ''}>{row.name}</ComponentsListTableRow>,
  },
  {
    label: 'Category',
    name: 'category',
    align: 'left',
    customRender: (row) => (
      <ComponentsListTableRow tooltip={row?.category || ''}>{row.category}</ComponentsListTableRow>
    ),
  },
];

export const videoTableColumns = [
  {
    label: 'Name',
    name: 'title',
    align: 'left',
    customRender: (row) => <ComponentsListTableRow tooltip={row?.title || ''}>{row.title}</ComponentsListTableRow>,
  },
  {
    label: 'Category',
    name: 'video_category.categoryName',
    align: 'left',
    customRender: (row) => (
      <ComponentsListTableRow tooltip={row.video_category?.categoryName ?? '-'}>
        {row.video_category?.categoryName ?? '-'}
      </ComponentsListTableRow>
    ),
  },
];

export const mockedResources = [
  {
    id: 'a',
    name: 'That one resource',
    category: 'iOS',
  },
  {
    id: 'b',
    name: 'Another resource',
    category: 'Android',
  },
  {
    id: 3,
    name: 'Lorem Ipsum',
    category: 'Android',
  },
];

export const mockedBios = [
  {
    id: 'a',
    name: 'John Smith',
    title: 'Boss',
    url: 'https://www.writeups.org/wp-content/uploads/Hannibal-John-Smith-A-Team-George-Peppard-b.jpg',
    image: 'https://www.writeups.org/wp-content/uploads/Hannibal-John-Smith-A-Team-George-Peppard-b.jpg',
    group: 'Employees',
  },
  {
    id: 'b',
    name: 'B.A. Baracus',
    title: 'Mechanic & Driver',
    url: 'https://m.media-amazon.com/images/M/MV5BMTQ5Nzg2MTgwMl5BMl5BanBnXkFtZTcwNTA0NjcxMw@@._V1_UY1200_CR91,0,630,1200_AL_.jpg',
    image:
      'https://m.media-amazon.com/images/M/MV5BMTQ5Nzg2MTgwMl5BMl5BanBnXkFtZTcwNTA0NjcxMw@@._V1_UY1200_CR91,0,630,1200_AL_.jpg',
    group: 'Employees',
  },
  {
    id: 'c',
    name: 'Templeton Peck',
    title: 'Faceman',
    url: 'https://i.pinimg.com/originals/b6/b2/bd/b6b2bdf23986ec1e232611c8b9d90d28.jpg',
    image: 'https://i.pinimg.com/originals/b6/b2/bd/b6b2bdf23986ec1e232611c8b9d90d28.jpg',
    group: 'Employees',
  },
  {
    id: 'd',
    name: 'H.M. Murdock',
    title: 'Pilot',
    url: 'https://images-na.ssl-images-amazon.com/images/I/51e62h9drWL._AC_.jpg',
    image: 'https://images-na.ssl-images-amazon.com/images/I/51e62h9drWL._AC_.jpg',
    group: 'A Team',
  },
];

export const mockedVideos = [
  {
    id: 1,
    name: 'Promotional Video',
    category: 'Dev',
    image: 'http://i3.ytimg.com/vi/unUXGT0cmZQ/maxresdefault.jpg',
  },
  {
    id: 2,
    name: 'Commercial',
    category: 'Dev',
    image: 'http://i3.ytimg.com/vi/PczERN-BQeo/maxresdefault.jpg',
  },
  {
    id: 3,
    name: 'Lorem Ipsum',
    category: 'QA',
    image: 'http://i3.ytimg.com/vi/otpRInHxR6k/maxresdefault.jpg',
  },
];
