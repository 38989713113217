import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MaterialTooltip from "@mui/material/Tooltip";

const StyledComponentsListTableRow = styled.div`
  min-width: 150px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 1280px) {
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : `300px`)};
  }
`;

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.textColor};

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.textColor};
  }
`;

const ComponentsListTableRow = ({ children, maxWidth, url, tooltip = '' }) => {
  return (
    <MaterialTooltip title={tooltip} placement="top-start">
      <StyledComponentsListTableRow maxWidth={maxWidth}>
        {url ? (
          <StyledLink href={url} target="_blank" rel="noopener noreferrer">
            {children}
          </StyledLink>
        ) : (
          children
        )}
      </StyledComponentsListTableRow>
    </MaterialTooltip>
  );
};

ComponentsListTableRow.propTypes = {
  maxWidth: PropTypes.number,
};

export default ComponentsListTableRow;
